define("ember-svg-jar/inlined/social-pinterest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><path d=\"M9 0a9 9 0 00-3.28 17.38c-.079-.711-.15-1.803.032-2.58.163-.703 1.055-4.474 1.055-4.474s-.27-.54-.27-1.337c0-1.25.726-2.185 1.629-2.185.767 0 1.139.577 1.139 1.268 0 .771-.492 1.926-.746 2.996-.212.895.45 1.627 1.333 1.627 1.6 0 2.829-1.687 2.829-4.122 0-2.155-1.548-3.661-3.76-3.661-2.56 0-4.063 1.92-4.063 3.905 0 .773.298 1.604.67 2.053a.27.27 0 01.062.26l-.25 1.02c-.04.164-.13.2-.301.12-1.124-.524-1.827-2.167-1.827-3.487 0-2.838 2.063-5.446 5.947-5.446 3.122 0 5.548 2.225 5.548 5.198 0 3.102-1.955 5.598-4.67 5.598-.912 0-1.77-.473-2.063-1.033l-.56 2.14c-.204.781-.752 1.762-1.12 2.359A9 9 0 109 0z\" fill=\"#142B6F\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
});