define("ember-svg-jar/inlined/card-mastercard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Mastercard</title><rect width=\"48\" height=\"32\" rx=\"3\" fill=\"#141413\"/><path d=\"M28.554 8.14h-8.746v15.715h8.746V8.14z\" fill=\"#FF5F00\"/><path d=\"M20.363 15.998c0-3.054 1.416-5.97 3.804-7.858-4.332-3.415-10.634-2.665-14.05 1.694C6.732 14.165 7.48 20.44 11.84 23.855a9.97 9.97 0 0012.356 0 10 10 0 01-3.832-7.857z\" fill=\"#EB001B\"/><path d=\"M40.354 15.998a9.99 9.99 0 01-9.995 9.995 9.893 9.893 0 01-6.164-2.138c4.331-3.415 5.08-9.69 1.666-14.049-.5-.61-1.056-1.194-1.666-1.666 4.331-3.415 10.634-2.665 14.021 1.694a9.893 9.893 0 012.138 6.164z\" fill=\"#F79E1B\"/>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});