define("ember-svg-jar/inlined/icon-close-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon X</title><path d=\"M16 1.511L14.489 0 8.034 6.455 1.511 0 0 1.511l6.455 6.523L0 14.489 1.511 16l6.523-6.455L14.489 16 16 14.489 9.545 8.034z\" fill=\"#142B6F\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});