define("ember-svg-jar/inlined/icon-star-half", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Fill</title><defs><path id=\"a\" d=\"M32.329 10.764l-9.365-1.337-4.208-8.359A1.942 1.942 0 0017.016 0c-.74 0-1.414.414-1.74 1.068l-4.188 8.359-9.366 1.337A1.933 1.933 0 00.096 12.07a1.892 1.892 0 00.53 2l6.787 6.506-1.6 9.19c-.124.716.174 1.44.77 1.868a1.963 1.963 0 002.042.147l8.367-4.337 8.376 4.337c.653.338 1.445.281 2.041-.147a1.898 1.898 0 00.77-1.868l-1.6-9.19 6.787-6.506c.556-.499.77-1.27.547-1.978a1.931 1.931 0 00-1.584-1.328z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#E9EEF1\" fill-opacity=\".56\" xlink:href=\"#a\"/><path fill=\"#142B6F\" fill-rule=\"nonzero\" mask=\"url(#b)\" d=\"M0 0h17.2v32H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "34",
      "height": "32",
      "viewBox": "0 0 34 32"
    }
  };
});