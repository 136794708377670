define("ember-svg-jar/inlined/logo-fedex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>FedEx Logo</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M19.984 10.467l.992 1.041.956-1.041h2.04l-1.994 2.135L24 14.754h-2.123l-.983-1.05-.975 1.05h-2.05l2.004-2.143-2.004-2.144h2.115zm-2.115 0v1.448H15.6v1.33h2.27v1.51h-3.937V8h3.937v1.506H15.6v.96h2.27z\" fill=\"#FF5900\"/><path d=\"M12.27 8v2.764h-.018c-.368-.402-.827-.542-1.36-.542-1.092 0-1.915.707-2.204 1.641-.33-1.03-1.179-1.66-2.438-1.66-1.023 0-1.83.436-2.252 1.149v-.885H1.884v-.961h2.307V8H0v6.754h1.884v-2.839h1.879a2.57 2.57 0 00-.086.668c0 1.409 1.13 2.398 2.573 2.398 1.213 0 2.013-.543 2.436-1.531H7.07c-.219.297-.384.385-.82.385-.507 0-.944-.42-.944-.92h3.288C8.738 14.036 9.654 15 10.91 15c.542 0 1.038-.254 1.342-.682h.018v.437h1.661V8h-1.66zm-6.91 3.935a.899.899 0 01.89-.709c.48 0 .812.272.9.71H5.36zm5.902 1.791c-.612 0-.993-.543-.993-1.11 0-.606.331-1.19.993-1.19.686 0 .96.584.96 1.19 0 .574-.29 1.11-.96 1.11z\" fill=\"#2A007C\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});