define("ember-svg-jar/inlined/card-applepay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" y=\".5\" width=\"47\" height=\"31\" rx=\"2.5\" stroke=\"#142B6F\"/><g clip-path=\"url(#clip0_2141_2336)\" fill=\"#000\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.219 10.82c.387-.484.65-1.134.58-1.8-.566.029-1.258.375-1.659.86-.36.415-.678 1.093-.595 1.73.636.055 1.273-.318 1.674-.79zM13.793 11.734c-.925-.055-1.711.525-2.152.525-.442 0-1.118-.497-1.85-.484-.951.014-1.834.553-2.317 1.409-.994 1.712-.262 4.251.704 5.646.469.69 1.034 1.45 1.779 1.422.704-.028.98-.456 1.835-.456s1.104.456 1.849.442c.772-.014 1.255-.69 1.725-1.38.538-.787.758-1.547.772-1.588-.014-.014-1.49-.58-1.504-2.278-.014-1.422 1.16-2.098 1.214-2.14-.662-.98-1.697-1.09-2.056-1.118z\"/><path d=\"M23.01 9.81c2.01 0 3.409 1.385 3.409 3.402 0 2.024-1.428 3.416-3.46 3.416h-2.224v3.538h-1.607V9.81h3.882zm-2.275 5.469h1.844c1.4 0 2.196-.754 2.196-2.06s-.796-2.052-2.188-2.052h-1.852v4.112zM26.817 18.02c0-1.327 1.012-2.088 2.878-2.203l2.002-.122v-.574c0-.84-.552-1.299-1.535-1.299-.811 0-1.4.416-1.522 1.055h-1.45c.044-1.342 1.307-2.318 3.015-2.318 1.837 0 3.035.962 3.035 2.454v5.153h-1.485v-1.242h-.036c-.424.811-1.357 1.32-2.368 1.32-1.493 0-2.534-.889-2.534-2.224zm4.88-.667v-.582l-1.787.115c-1.004.065-1.528.438-1.528 1.091 0 .632.545 1.04 1.4 1.04 1.09 0 1.915-.695 1.915-1.664z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M34.606 22.936v-1.241c.1.014.344.028.473.028.71 0 1.113-.3 1.357-1.076l.143-.46-2.72-7.534h1.68l1.894 6.114h.036l1.895-6.114H41l-2.82 7.915c-.646 1.816-1.385 2.411-2.95 2.411-.122 0-.517-.014-.624-.043z\"/></g><defs><clipPath id=\"clip0_2141_2336\"><path fill=\"#fff\" transform=\"translate(7 9.02)\" d=\"M0 0h34v13.959H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});