define("ember-svg-jar/inlined/icon-flavor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Icon-Flavor</title><path d=\"M33 20.001C33 27.18 27.178 33 20.002 33 12.822 33 7 27.18 7 20.001 7 12.82 12.822 7 20.002 7 27.178 7 33 12.82 33 20.001zM16.386 15.46l3.368 3.945m.29-3.657l-3.947 3.369\" stroke=\"#142B6F\" stroke-width=\"2.4\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});