define("ember-svg-jar/inlined/card-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 .5h42A2.5 2.5 0 0147.5 3v26a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 29V3A2.5 2.5 0 013 .5z\" stroke=\"#142B6F\"/><g clip-path=\"url(#clip0_3174_12889)\"><path d=\"M13.362 21.362a5.362 5.362 0 100-10.724 5.362 5.362 0 000 10.724z\" fill=\"#00D66F\"/><path d=\"M34.23 11.47h1.581v4.803c1.178-.281 2.005-1.194 2.541-2.234h1.536c-.294 1.32-1.321 2.28-2.346 3.071 1.236.687 2.229 1.995 2.458 3.408h-1.62c-.308-1.252-1.254-2.426-2.569-2.68v2.68H34.23M32.963 20.518h-1.62v-4.16c0-.447-.332-.824-.578-.931-1.088-.477-2.047.507-2.047 1.768v3.323h-1.564v-6.423h1.564l.028.67c1.093-1.55 4.217-.843 4.217 1.09v4.663zM21.401 20.518V11.47h1.536l.084.084v8.88l-.084.084h-1.536zM13.135 12.755c.401 1.332 1.59 2.294 2.936 2.566l-.03 1.374c-1.348.21-2.493 1.25-2.906 2.539H11.46c.235-1.402 1.3-2.427 2.382-3.249-1.13-.714-2.126-1.888-2.383-3.23h1.676zM25.87 14.095h-1.62v6.423h1.62v-6.423zM24.905 11.37c1.296-.167 1.442 1.722.258 1.827-1.24.11-1.374-1.682-.258-1.826z\" fill=\"#011E0F\"/></g><defs><clipPath id=\"clip0_3174_12889\"><path fill=\"#fff\" transform=\"translate(8 10.638)\" d=\"M0 0h32v10.724H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});