define("ember-svg-jar/inlined/card-truemed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Truemed</title><path d=\"M.5 3A2.5 2.5 0 013 .5h42A2.5 2.5 0 0147.5 3v26a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 29V3z\" fill=\"#fff\"/><path d=\"M.5 3A2.5 2.5 0 013 .5h42A2.5 2.5 0 0147.5 3v26a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 29V3z\" stroke=\"#142B6F\"/><g clip-path=\"url(#clip0_3187_6023)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.198 21.98l5.223-5.453h-7.737c.022.325.062.648.12.97a8.25 8.25 0 002.394 4.483zm4.304-14.173a8.342 8.342 0 00-6.758 7.045h16.502a8.327 8.327 0 00-9.744-7.045zm-2.98 15.216a8.349 8.349 0 008.94.016l-4.478-4.675-4.462 4.659zm11.33-2.285a8.315 8.315 0 001.459-4.21l-7.765.003 5.238 5.466a8.453 8.453 0 001.068-1.26zM15.775 10.313c3.14-4.544 9.37-5.682 13.914-2.54 4.54 3.146 5.675 9.374 2.537 13.92-3.142 4.539-9.368 5.674-13.91 2.535-4.544-3.14-5.682-9.37-2.541-13.915zm6.334 1.174a1.809 1.809 0 113.618 0 1.809 1.809 0 01-3.618 0z\" fill=\"#179895\"/></g><defs><clipPath id=\"clip0_3187_6023\"><path fill=\"#fff\" transform=\"translate(14 5.997)\" d=\"M0 0h20v20.005H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});