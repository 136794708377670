define("ember-svg-jar/inlined/card-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Visa</title><path d=\"M3 .5h42A2.5 2.5 0 0147.5 3v26a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 29V3A2.5 2.5 0 013 .5z\" stroke=\"#142B6F\"/><g clip-path=\"url(#clip0_2141_2285)\"><path d=\"M18.912 10.694l-4.453 10.638h-2.904l-2.19-8.49c-.134-.521-.249-.712-.652-.935-.662-.359-1.751-.696-2.711-.904l.064-.31h4.675c.594 0 1.132.398 1.268 1.085l1.156 6.157 2.859-7.241h2.888zM30.29 17.86c.012-2.806-3.874-2.962-3.848-4.218.01-.38.37-.787 1.166-.89.394-.05 1.479-.091 2.709.476l.482-2.257a7.407 7.407 0 00-2.57-.469c-2.716 0-4.627 1.446-4.643 3.518-.017 1.531 1.364 2.386 2.405 2.895 1.072.522 1.43.857 1.426 1.321-.007.713-.855 1.03-1.643 1.041-1.383.022-2.183-.373-2.821-.672l-.5 2.333c.643.294 1.828.55 3.056.564 2.887 0 4.774-1.428 4.784-3.64m7.172 3.475h2.542l-2.224-10.643H35.44a1.25 1.25 0 00-1.17.78l-4.123 9.858h2.885l.574-1.588h3.525l.332 1.588.002.005zm-3.067-3.768l1.448-3.996.831 3.996h-2.279zm-11.562-6.875l-2.272 10.638h-2.746l2.271-10.638h2.747z\" fill=\"#1434CB\"/></g><defs><clipPath id=\"clip0_2141_2285\"><path fill=\"#fff\" transform=\"translate(7 10.5)\" d=\"M0 0h34v11H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});