define("ember-svg-jar/inlined/checkbox-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#7F94B7\" stroke=\"#142B6F\" stroke-width=\"2\"/><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M10.157 17.01c-.145.148-.35.27-.53.27-.18 0-.385-.129-.536-.276L5.72 13.576l1.637-1.623 2.276 2.282 6.986-7.07L18.2 8.788l-8.043 8.223z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
});