define("ember-svg-jar/inlined/card-microsoftpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 .5h42A2.5 2.5 0 0147.5 3v26a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 29V3A2.5 2.5 0 013 .5z\" stroke=\"#142B6F\"/><g clip-path=\"url(#clip0_3174_12836)\"><path d=\"M22.998 15.91v4.037h-1.261V9.982h3.345a2.974 2.974 0 012.162.866 2.906 2.906 0 01.14 4.068l-.14.143c-.588.565-1.308.85-2.162.85h-2.084zm0-4.704v3.48h2.115c.47.016.924-.175 1.246-.517a1.771 1.771 0 00-.04-2.47 1.707 1.707 0 00-1.206-.493h-2.115zM31.059 12.906c.932 0 1.668.254 2.21.755.54.5.806 1.2.806 2.082v4.204h-1.207V19h-.054c-.525.779-1.215 1.168-2.084 1.168-.736 0-1.363-.223-1.857-.668a2.12 2.12 0 01-.744-1.668c0-.707.266-1.264.791-1.685.525-.421 1.23-.628 2.108-.628.752 0 1.363.143 1.848.414v-.294c0-.438-.188-.85-.517-1.137a1.819 1.819 0 00-1.214-.469c-.705 0-1.261.302-1.668.906l-1.113-.707c.595-.882 1.496-1.327 2.695-1.327zm-1.63 4.95c0 .334.157.644.416.835.282.223.626.342.979.334a1.99 1.99 0 001.418-.596c.415-.398.627-.866.627-1.407-.392-.318-.94-.477-1.645-.469-.51 0-.94.127-1.285.374-.337.246-.51.556-.51.93z\" fill=\"#010101\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M41 13.129l-4.215 9.813h-1.3l1.567-3.432-2.766-6.381h1.371l1.998 4.895h.023l1.951-4.895h1.37z\" fill=\"#010101\"/><path d=\"M18.516 15.154h-5.42v5.42h5.42v-5.42z\" fill=\"#FEC200\"/><path d=\"M12.42 15.154H7v5.42h5.42v-5.42z\" fill=\"#00B4F1\"/><path d=\"M18.516 9.058h-5.42v5.419h5.42v-5.42z\" fill=\"#91C300\"/><path d=\"M12.42 9.058H7v5.419h5.42v-5.42z\" fill=\"#F8682C\"/></g><defs><clipPath id=\"clip0_3174_12836\"><path fill=\"#fff\" transform=\"translate(7 9.058)\" d=\"M0 0h34v13.885H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});