define("ember-svg-jar/inlined/card-amex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Amex</title><path d=\"M45.824 28.501l-2.219-2.474-2.304 2.474h-14.25v-11.52h-4.523L28.16 4.096h5.461l1.963 4.437V4.096h6.827l1.194 3.328L44.8 4.096h5.205V2.133A2.113 2.113 0 0047.872 0H2.133A2.113 2.113 0 000 2.133v27.734C0 31.06.939 32 2.133 32h45.739a2.113 2.113 0 002.133-2.133V28.5h-4.181z\" fill=\"#0071CE\"/><path d=\"M46.421 27.136h3.584l-4.693-5.035 4.693-4.949h-3.498l-2.987 3.243-2.901-3.243h-3.584l4.778 5.035-4.778 4.95h3.498l2.987-3.244 2.901 3.243zM31.317 24.832v-1.536h5.632v-2.304h-5.632v-1.536h5.718v-2.304h-8.448v9.984h8.448v-2.304h-5.718zM47.36 15.701h2.56V5.632l-4.096.085-2.219 6.23-2.304-6.23h-4.266v9.984h2.73V8.704l2.56 6.997h2.39l2.645-6.997v6.997z\" fill=\"#0071CE\"/><path d=\"M32.683 5.718h-3.499L24.747 15.7h2.986l.854-1.962h4.608l.853 1.962h3.072l-4.437-9.983zm-3.158 5.717l1.366-3.243 1.365 3.243h-2.73zM47.53 21.845l2.475 2.731v-5.461l-2.474 2.73z\" fill=\"#0071CE\"/>",
    "attrs": {
      "width": "50",
      "height": "32",
      "viewBox": "0 0 50 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});