define("ember-svg-jar/inlined/icon-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon-eye</title><path d=\"M19.648 34.372l.264.003a.236.236 0 01-.026.001l-.143-.001-.095-.003zm.352.004h-.064l-.016-.001.08.001zm-.114-17c-4.066 0-7.375 3.309-7.375 7.375s3.309 7.375 7.375 7.375 7.375-3.309 7.375-7.375c0-4.065-3.31-7.375-7.375-7.375zm7.54 1.4l.103.13a9.577 9.577 0 011.982 5.845c0 2.238-.767 4.3-2.053 5.936 3.791-1.83 6.016-4.675 6.87-5.944-.857-1.29-3.074-4.148-6.902-5.967zm-15.254.223l-.157.082c-3.471 1.828-5.529 4.467-6.34 5.676.827 1.248 2.927 3.963 6.527 5.786a9.577 9.577 0 01-1.941-5.792c0-2.155.711-4.146 1.911-5.752zM6.135 9.194a1.16 1.16 0 011.644 0l2.629 2.627a1.163 1.163 0 01-1.644 1.644l-2.629-2.627a1.163 1.163 0 010-1.644zm27.567 0a1.16 1.16 0 01.09 1.541l-.09.102-2.63 2.627a1.159 1.159 0 01-1.643.002 1.16 1.16 0 01-.09-1.542l.09-.101 2.63-2.63a1.161 1.161 0 011.643 0zm-13.7-4.068c.6 0 1.093.452 1.156 1.035l.007.126v3.717a1.163 1.163 0 01-2.32.127l-.006-.127V6.287c0-.64.52-1.161 1.163-1.161zM20 34.376l-.088-.001h.008l-.32-.003h.048-.09c-7.314-.141-12.424-3.784-15.545-8.488l-.752-1.135.76-1.13c3.16-4.7 8.297-8.359 15.575-8.49l.145-.002.26-.001c7.55 0 12.799 3.69 15.987 8.49l.755 1.135-.762 1.13c-3.162 4.699-8.283 8.35-15.543 8.49l-.438.005z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});