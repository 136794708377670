define("ember-svg-jar/inlined/logo-dhl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 4.34h23.998v15.312H0V4.34z\" fill=\"#FFCB01\"/><path d=\"M4.46 10.17l-.744 1.01h4.05c.205 0 .203.076.103.213-.102.138-.272.376-.376.517-.052.07-.147.2.167.2h1.657l.49-.667c.305-.413.027-1.273-1.061-1.273H4.459z\" fill=\"#D80613\"/><path d=\"M3.415 13.46l1.493-2.03h1.853c.205 0 .202.078.102.214l-.378.515c-.053.07-.148.2.167.2h2.481c-.205.283-.876 1.1-2.077 1.1h-3.64zm8.55-1.102l-.81 1.101H9.019l.81-1.1h2.135zm3.27-.249h-5.222l1.428-1.94h2.134l-.818 1.113h.953l.819-1.113h2.134l-1.428 1.94zm-.183.25l-.81 1.1h-2.134l.81-1.1h2.134zm-14.683.434h3.145l-.172.233H.37v-.233zm0-.434h3.465l-.172.233H.369v-.234zm0 .868h2.826l-.171.232H.369v-.232zm23.261-.2h-3.134l.172-.234h2.962v.233zm0 .432h-3.452l.17-.232h3.282v.232zm-2.642-1.1h2.642v.233h-2.814l.172-.234zm-1.368-2.19l-1.428 1.94H15.93l1.429-1.94h2.26zm-3.873 2.19l-.232.315c-.268.363-.031.785.844.785h3.428l.81-1.1h-4.85z\" fill=\"#D80613\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});