define("ember-svg-jar/inlined/icon-skip-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon-skip-calendar</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h48v48H0z\"/><path d=\"M32.802 7c.6 0 1.087.487 1.087 1.087v1.957l9.024.001c.6 0 1.087.487 1.087 1.087v29.5c0 .6-.487 1.086-1.087 1.086H5.087c-.6 0-1.087-.487-1.087-1.087V11.132c0-.6.487-1.087 1.087-1.087h8.73V8.086a1.087 1.087 0 112.174 0v1.957h15.724V8.087c0-.6.487-1.087 1.087-1.087zm9.024 12.162H6.173v20.382h35.653V19.162zm-28.01-6.944l-7.642.001-.001 4.77h35.653v-4.77l-7.938-.001v2.03a1.087 1.087 0 11-2.173 0v-2.03H15.99v2.03a1.087 1.087 0 11-2.173 0v-2.03z\" fill=\"#142B6F\"/><path d=\"M23.771 35.191a5.987 5.987 0 114.01-1.54m-.7-3.105l.674 3.172 3.173-.674\" stroke=\"#142B6F\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});