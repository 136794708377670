define("ember-svg-jar/inlined/ritual-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Ritual</title><path fill=\"#142B6F\" d=\"M83.784 109.63L62.117 77.18c13.142-6.564 20.489-18.159 20.489-32.511 0-21.472-16.011-37.617-44.441-37.617H5.232V109.63h18.426V82.282h20.054l17.986 27.348h22.086zM40.05 63.422H23.658V25.914H40.05c13.055 0 23.209 5.842 23.209 18.755.001 12.909-10.154 18.753-23.209 18.753m288.962-56.37h18.422V109.63h-18.422V7.052zM146.311 52.728v34.023c.293 4.523 2.319 6.639 6.316 6.639 3.992 0 6.968-1.756 9.386-3.688v17.351c-3.53 2.479-9.519 4.275-16.169 3.881-4.423-.264-9.607-2.161-13.243-5.9-3.146-3.93-4.72-7.829-4.72-14.452V52.728h-9.421v-16.66h9.421V12.132h18.43v23.936h12.572v16.66h-12.572zm91.52 56.887h-16.523l-1.71-8.384c-3.386 4.416-10.345 9.267-20.496 9.267-18.24 0-30.638-10.736-30.638-30.301V36.069h18.231v37.983c0 12.944 5.784 18.535 16.379 18.535 10.589 0 16.523-7.649 16.523-18.535V36.069h18.233v73.546zm81.704-73.457v73.472h-14.747l-1.712-7.642c-3.085 3.968-11.164 8.522-21.011 8.522-21.011 0-36.886-15.576-36.886-37.615 0-22.043 15.875-37.62 36.886-37.62 9.697 0 17.926 4.556 21.011 8.523l1.712-7.641h14.747zm-36.907 16.518c-11.188 0-19.813 9.167-19.813 20.22 0 11.05 8.626 20.215 19.813 20.215 10.918 0 19.275-9.165 19.275-20.215 0-11.053-8.357-20.22-19.275-20.22M91.493 36.066h18.425v73.562H91.493V36.066zm21.116-20.469c0 6.705-5.363 12.14-11.977 12.14-6.615 0-11.978-5.435-11.978-12.14 0-6.703 5.362-12.138 11.978-12.138 6.614 0 11.977 5.435 11.977 12.138\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "ease-animation-slow",
      "viewBox": "0 0 352 115.333"
    }
  };
});