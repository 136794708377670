define("ember-svg-jar/inlined/card-discover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 .5h42A2.5 2.5 0 0147.5 3v26a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 29V3A2.5 2.5 0 013 .5z\" stroke=\"#142B6F\"/><g clip-path=\"url(#clip0_3174_12821)\"><path d=\"M7.651 13.118H6v5.774h1.646c.872 0 1.507-.206 2.062-.667a2.902 2.902 0 001.05-2.216c0-1.702-1.274-2.891-3.102-2.891H7.65zm1.315 4.337c-.354.317-.811.457-1.539.457h-.303v-3.82h.303c.728 0 1.17.131 1.54.467.391.345.624.886.624 1.436 0 .55-.233 1.11-.625 1.455v.005zM12.394 13.118H11.27v5.774h1.124v-5.774zM15.146 15.333c-.677-.252-.872-.415-.872-.728 0-.364.354-.639.84-.639.335 0 .615.14.909.467l.587-.77a2.523 2.523 0 00-1.697-.639c-1.022 0-1.8.71-1.8 1.656 0 .797.363 1.203 1.422 1.58.438.155.667.262.779.327a.688.688 0 01.335.597c0 .467-.373.816-.876.816-.537 0-.97-.27-1.232-.77l-.727.7c.518.76 1.143 1.101 2 1.101 1.171 0 1.992-.779 1.992-1.893 0-.92-.378-1.334-1.66-1.8v-.005zM17.165 16.01c0 1.697 1.334 3.012 3.046 3.012.485 0 .9-.093 1.413-.336v-1.324c-.453.452-.849.634-1.362.634-1.133 0-1.94-.82-1.94-1.991 0-1.11.83-1.983 1.889-1.983.536 0 .942.192 1.413.649v-1.325c-.495-.252-.9-.354-1.385-.354-1.707 0-3.074 1.343-3.074 3.022v-.005zM30.55 16.998l-1.539-3.88H27.78l2.453 5.923h.607l2.49-5.923h-1.217l-1.563 3.88zM33.843 18.892h3.19v-.975h-2.066v-1.558h1.991v-.98h-1.991v-1.282h2.066v-.98h-3.19v5.774zM41.496 14.824c0-1.082-.746-1.702-2.042-1.702h-1.67v5.774h1.124v-2.318h.15l1.557 2.318H42l-1.819-2.435c.849-.172 1.315-.75 1.315-1.637zm-2.257.952h-.331v-1.75h.345c.7 0 1.082.295 1.082.859 0 .564-.382.89-1.1.89h.004z\" fill=\"#231F20\"/><path d=\"M28.102 16a3.058 3.058 0 01-3.06 3.06 3.055 3.055 0 01-3.06-3.06 3.055 3.055 0 013.06-3.06 3.055 3.055 0 013.06 3.06z\" fill=\"#F47922\"/></g><defs><clipPath id=\"clip0_3174_12821\"><path fill=\"#fff\" transform=\"translate(6 12.94)\" d=\"M0 0h36v6.119H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});