define("ember-svg-jar/inlined/yellow-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><path d=\"M5.333 11.663c-.174.184-.42.337-.637.337-.217 0-.463-.16-.644-.344L0 7.37l1.288-1.362L4.703 9.62 13.733 0 15 1.385 5.333 11.663z\" fill=\"#FFD600\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "12",
      "viewBox": "0 0 15 12"
    }
  };
});