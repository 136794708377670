define("ember-svg-jar/inlined/dash-connector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><g fill=\"#D8D8D8\" fill-rule=\"evenodd\"><path d=\"M0 0h20v5H0zM30 0h20v5H30zM60 0h20v5H60z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "5",
      "viewBox": "0 0 80 5"
    }
  };
});