define("ember-svg-jar/inlined/icon-checkmark-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><path d=\"M13 26C5.82 26 0 20.18 0 13S5.82 0 13 0s13 5.82 13 13-5.82 13-13 13zm0-2c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11z\" fill=\"#9FAFC9\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
});