define("ember-svg-jar/inlined/pause-skip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#F2F1F5\" stroke=\"#9FAFC9\" stroke-width=\"3\" d=\"M37 15.501c0 .576-20 13.904-20 13.49V2.01c0-.464 20 12.914 20 13.49z\"/><path fill=\"#9FAFC9\" d=\"M8 3.417C8 2.568 8 1 9.5 1S11 2.551 11 3.417v24.166C11 28.291 11 30 9.535 30 8.069 30 8 28.365 8 27.583V3.417zM0 3.417C0 2.568 0 1 1.5 1S3 2.551 3 3.417v24.166C3 28.291 3 30 1.535 30 .069 30 0 28.365 0 27.583V3.417z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "39",
      "height": "31",
      "viewBox": "0 0 39 31"
    }
  };
});